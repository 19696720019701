<template>
    <a-card>
        <a-page-header
            :title="id == 0 ? '上传视频' : '编辑视频'"
        />

        <a-form-model
            ref="form"
            v-bind='layout'
            :model="form"
            :rules='rules'
        >
            <a-form-model-item label=" " :colon="false" ref="videoId" prop="videoId" v-if="id == 0 || pageType == 'copy'">
                <base-upload
                    :upload-title="''"
                    :accept-list="'.mp4,.mpeg,.3gp,.avi'"
                    :has-file-list="uploadFileList"
                    :params-data="uploadParamsData"
                    :limit-size="100"
                    :is-new-create-vod="true"
                    @handleUrl="handleUrl"
                    @handlePreview="handlePreviewOpen(arguments)"
                />
                <div class="txt">{{ videoFullTitle }}</div>
                <div class="txt">支持扩展名：mp4、mpeg、3gp、avi  大小≤100M</div>
            </a-form-model-item>
            <a-form-model-item label="视频标题" v-else>
                {{ form.videoTitle || '-' }}
            </a-form-model-item>
            <a-form-model-item label="投放用途" prop="deliveryPurpose">
                <a-radio-group v-model="form.deliveryPurpose">
                    <a-radio :value="1">销售线索收集</a-radio>
                    <a-radio :value="2">直播预热</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="视频适用范围" prop='videoScope'>
                <a-radio-group v-model="form.videoScope" @change="onChangeScope">
                    <a-radio :value="1">车系</a-radio>
                    <a-radio :value="2">品牌</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                <a-select
                    v-model="form.principalId"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="form.videoScope != 2">
                <a-select
                    v-model="form.carSeriesId"
                    placeholder="请选择车系"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    :disabled="!form.principalId"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="集客场景" prop='customerScene' style="margin-top: 24px;">
                <a-radio-group v-model="form.customerScene">
                    <a-radio
                        v-for="item in customerSceneList"
                        :key="item.code"
                        :value="item.code"
                    >
                        {{ item.desc }}
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="集客方式" prop="customerTypeArr">
                <a-checkbox-group
                    v-model="form.customerTypeArr"
                >
                    <a-checkbox v-for="item in typeList" :key="item.value" :value="item.value">{{ item.text }}</a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="视频有效日期" ref="videoValid" prop="videoValid">
                <a-radio-group v-model="form.videoValid" @change="onChangeValid">
                    <a-radio :value="1">长期</a-radio>
                    <a-radio :value="2">
                        截止
                        <a-date-picker
                            v-model="dateValue"
                            placeholder="请选择定时发布时间"
                            :disabled-date="disabledDate"
                            :disabled="form.videoValid != 2"
                            @change="onChangeDate"
                        />
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <!-- <a-form-model-item label="关联项目">
                <a-select
                    v-model="form.projectId"
                    placeholder="请选择关联项目"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                >
                    <a-select-option
                        v-for="item in projectList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="导拍模板">
                <a-select
                    v-model="form.guideTemplateId"
                    placeholder="请选择导拍模板"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                    @search="getTemplateList"
                >
                    <a-select-option
                        v-for="item in moduleList"
                        :key="item.id"
                    >
                        {{ item.guideshootName }} (ID：{{ item.id }})
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="视频标题" ref="videoTitle" prop="videoTitle" v-if="id == 0 || pageType == 'copy'">
                <wordLimitInput
                    :value="form.videoTitle"
                    :maxLength="30"
                    @onChangeInput="e => onChangeInput(e, 'videoTitle')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="广告平台" prop="adPlatformTypeArr" v-if="id == 0 || pageType == 'copy'">
                <a-checkbox-group
                    v-model="form.adPlatformTypeArr"
                >
                    <a-checkbox v-for="item in platformList" :key="item.adPlatformType" :value="item.adPlatformType">{{ item.adPlatformName }}</a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="广告主" prop="advertiserIdArr" v-if="id == 0 || pageType == 'copy'">
                <a-select
                    mode="multiple"
                    v-model="form.advertiserIdArr"
                    placeholder="请选择广告主"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                >
                    <a-select-option
                        v-for="item in advertiserList"
                        :key="item.advertiserId"
                    >
                        {{ item.advertiserName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import debounce from 'lodash/debounce'
import moment from 'moment';
import wordLimitInput from '@/components/wordLimitInput'
import BaseUpload from '@/components/BaseUpload/BaseUpload';
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

export default {
    components: {
        wordLimitInput,
        BaseUpload,
        previewModal
    },
    data() {
        this.getAccountList = debounce(this.getAccountList, 1000)
        this.getTemplateList = debounce(this.getTemplateList, 1000)

        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        let checkVideoValidDate = (rule, value, callback) => {
            if (!value || value == 2 && !this.dateValue) {
                callback(new Error('请选择视频有效日期'))
            } else {
                callback();
            }
        }
        let checkVideoId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请上传视频'))
            } else {
                callback();
            }
        }

        const typeList = [
            {
                value: 'FORM',
                text: '表单'
            },
            {
                value: 'MESSAGE',
                text: '私信'
            }
        ]

        return {
            id: this.$route.query.id,
            pageType: this.$route.query.type,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                // id: this.$route.query.id,
                videoId: '',
                videoTitle: '',
                videoOriginUrl: '',
                deliveryPurpose: 1,
                videoScope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                customerScene: 1,
                videoValid: 1,
                videoValidDate: '长期',
                adPlatformTypeArr: [],
                advertiserIdArr: [],
                guideTemplateId: undefined,
                hjkAdVideoId: undefined,
            },
            typeList,
            rules: {
                videoId: [
                    { validator: checkVideoId, trigger: 'change' }
                ],
                deliveryPurpose: [
                    { required: true, message: '请选择组件适用范围', trigger: 'change' }
                ],
                videoScope: [
                    { required: true, message: '请选择组件适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ],
                customerTypeArr: [
                    { required: true, message: '请选择集客方式', trigger: 'change' }
                ],
                videoValid: [
                    { required: true, validator: checkVideoValidDate, trigger: 'change' }
                ],
                videoTitle: [
                    { required: true, message: '请输入视频标题', trigger: 'change' }
                ],
                adPlatformTypeArr: [
                    { required: true, message: '请选择广告平台', trigger: 'change' }
                ],
                advertiserIdArr: [
                    { required: true, message: '请选择广告主', trigger: 'change' }
                ],
            },
            isSubmit: false,
            uploadFileList: [],
            uploadParamsData: {
                appId: "ad-aliyun-vod",
                businessCodeId: "1112170867613978628",
                filePath: '/皮皮慧投/投放视频'
            },
            principalList: [],
            seriesList: [],
            advertiserList: [],
            projectList: [],
            moduleList: [],
            previewVisible: false,
            previewSrc: '',
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            platformList: [],
            dateValue: null,
            videoFullTitle: '',
        }
    },
    created() {
        this.getPrincipalList()
        this.getCustomerScene()
        this.getAccountList()
        this.getTemplateList()
        this.getAdPlatformList()

        if(this.id != 0) {
            this.getDeliveryVideoDetail()
        }
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleUrl(file) {
            let name = ''
            let videoId = ''
            try {
                name = file.name.split('.')[0]
                videoId = file.response.videoId
            } catch(res) {
                name = ''
                videoId = ''
            }

            this.videoFullTitle = file.name
            this.form.videoOriginUrl = file.xhr
            this.form.videoId = videoId

            this.$refs.videoId.onFieldChange()

            if(!this.form.videoTitle) {
                this.form.videoTitle = name
                this.$refs.videoTitle.onFieldChange()
            }
        },
        handlePreviewOpen(data) {
            this.previewVisible = true
            this.previewSrc = data[0]
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        //获取广告主列表
        getAccountList(advertiserName) {
            this.$api.core.pphtManage.getAccountList({ advertiserName: advertiserName, page: 1, size: 1000 }).then((res) => {
                if(res.code == 200) {
                    this.advertiserList = res.data.list
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //获取导拍模板列表
        getTemplateList(guideshootName) {
            let params = {
                guideshootName,
                size: 1000,
                page: 1
            }
            this.$api.core.materialManage.getTemplateList(params).then((res) => {
                if(res.code == 200) {
                    this.moduleList = res.data.list
                } else {
                    this.moduleList = []
                    console.error(`获取模板列表失败${res}`)
                }
            })
        },
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                    if(this.form.adPlatformTypeArr.length == 0) {
                        this.form.adPlatformTypeArr = [this.platformList[0].adPlatformType]
                    }
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        onChangeValid() {
            if(this.form.videoValid == 1) {
                this.form.videoValidDate = '长期'
            } else {
                this.form.videoValidDate = ''
            }
            this.dateValue = null
        },
        onChangeDate(date, dateString) {
            this.form.videoValidDate = dateString
            this.$refs.videoValid.onFieldChange()
        },
        disabledDate(current) {
            return  current && current < moment().endOf('day');
        },
        onChangeInput(val, type) {
            this.form[type] = val
            this.$refs[type].onFieldChange()
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/castVideoManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    if(this.form.videoScope == 2) {
                        this.form.carSeriesId = undefined
                    }
                    let type = 'add'
                    if(this.pageType == 'copy') {
                        this.form.hjkAdVideoId = undefined
                    } else if(this.id != 0) {
                        type = 'edit'
                    }
                    let body = {
                        data: this.form,
                        type: type
                    }
                    console.log(body, type, this.pageType)
                    this.$api.core.materialManage.submitDeliveryVideoDetail( body ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/castVideoManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        getDeliveryVideoDetail() {
            this.$api.core.materialManage.getDeliveryVideoDetail( this.id ).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    let content = {
                            ..._data,
                            ...{
                            // videoTitle: _data.videoTitle,
                            // principalId: _data.principalId,
                            // carSeriesId: _data.carSeriesId,
                            // projectId: _data.projectId,
                            // guideTemplateId: _data.guideTemplateId,
                            customerScene: _data.customerScene || 1,
                            deliveryPurpose: _data.deliveryPurpose || 1,
                            hjkAdVideoId: _data.id,
                            videoScope: _data.videoScope || 1,
                            videoValidDate: _data.videoValidDate || '长期',
                            adPlatformTypeArr: (_data.adPlatformType ?? '') !== '' ? [_data.adPlatformType] : [],
                            advertiserIdArr: (_data.advertiserId ?? '') !== '' ? [_data.advertiserId] : [],
                        }
                    }

                    if(_data.videoScope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                    if(_data.videoValidDate && _data.videoValidDate != '长期') {
                        content.videoValid = 2
                        this.dateValue = moment(_data.videoValidDate)
                    } else {
                        content.videoValid = 1
                    }

                    if(this.pageType == 'copy') {
                        this.uploadFileList = [
                            {
                                uid: 0,
                                name: 'video',
                                status: 'done',
                                url: _data.videoOriginUrl,
                                thumbUrl: 'http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg',
                                editor: true,
                            },
                        ]
                    }

                    this.$set(this, 'form', content)
                } else {
                    console.error(`获取详情失败，${res}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.txt {
    color: #999;
    line-height: 1.5;
}
</style>
